<template>
  <div id="Navbar">
    <div class="dashboard-header">
      <b-navbar toggleable="lg" type="dark" variant="light" fixed="top">
        <b-navbar-brand to="/">Welcome</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <font-awesome-icon :icon="['fas', 'angle-down']" />
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-button to="/create" size="sm" class="mr-sm-2" variant="success">
              Create&nbsp;&nbsp;<font-awesome-icon
                :icon="['fas', 'plus-circle']"
              />
            </b-button>
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" placeholder="Search">
              </b-form-input>
            </b-nav-form>

            <b-nav-item-dropdown text="Account" right>
              <div class="nav-user-info">
                <h5 class="mb-0 text-white nav-user-name">
                  {{ $auth.user.name }}
                </h5>
                <span class="status"></span><span class="ml-2">Available</span>
              </div>
              <b-dropdown-item to="/account">
                <font-awesome-icon :icon="['fas', 'user']" />
                &nbsp; Account
              </b-dropdown-item>
              <b-dropdown-item to="/settings">
                <font-awesome-icon :icon="['fas', 'cog']" />
                &nbsp; Settings
              </b-dropdown-item>
              <b-dropdown-item @click="logout">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
                &nbsp; Logout</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      name: this.$store.state.name,
      role: this.$store.state.role,
      token: this.$store.state.token,
    };
  },
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style></style>
