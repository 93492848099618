<template>
  <div id="Search">
    <b-input-group class="mb-4">
      <b-form-input :value="search" placeholder="Search" @input="changeSearch">
      </b-form-input>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: ["search"],
  methods: {
    changeSearch(event) {
      this.$emit("searchChanged", event);
    },
  },
};
</script>

<style></style>
