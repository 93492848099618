import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    name: '',
    role: '',
  },
  mutations: {
    setUserName: (state, name) => {
      state.name = name
    },
    setUserRole: (state, role) => {
      state.role = role
    },
    setToken: (state, token) => {
      state.token = token
    },
    logout: (state) => {
      state.token = null
      state.name = ''
      state.role = ''
    }
  },
  actions: {
    logout({ commit }) {
      localStorage.removeItem('token');
      commit('logout');
      router.push('/login');
    }
  },
  modules: {
  }
})
