<template>
  <div id="404ErrorPage">
    <div class="dashboard-main-wrapper">
      <Header title="Error 404" subtitle="Page Not Found!" breadcrumb="" />
      <div class="ecommerce-widget">That page could not be found!</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "404ErrorPage",
  components: {
    Header,
  },
};
</script>

<style></style>
