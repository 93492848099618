<template>
  <div id="EditPage">
    <div class="dashboard-main-wrapper">
      <Header title="Edit" subtitle="This is the edit page" breadcrumb="" />
      <div class="ecommerce-widget">
        <b-row>
          <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
            <div v-if="type === 'events'">
              <EventForm />
            </div>
            <div v-if="type === 'sermons'">
              <SermonForm />
            </div>
            <div v-if="type === 'photos'"></div>
            <div v-if="type === 'videos'"></div>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import EventForm from "@/components/EventForm.vue";
import SermonForm from "@/components/SermonForm.vue";

export default {
  name: "EditPage",
  data() {
    return {
      type: this.$route.params.type,
      itemID: this.$route.params.id,
    };
  },
  components: {
    Header,
    EventForm,
    SermonForm,
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
