<template>
  <div id="Table">
    <b-overlay :show="formProcessing" rounded="sm">
      <b-row>
        <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="itemsAreLoaded">
            <Search :search="search" @searchChanged="search = $event" />
            <FilterResults
              :type="type"
              :filterBy="filter"
              :filterByOptions="filterByOptions"
              @filterByChanged="filter = $event"
              :perPage="perPage"
              :perPageOptions="perPageOptions"
              @perPageChanged="perPage = $event"
            />
          </div>
          <div v-else>
            <b-input-group class="mb-4">
              <b-form-input disabled></b-form-input>
            </b-input-group>
            <b-row class="mb-4">
              <b-col class="d-flex justify-content-between">
                <!-- Type Options -->
                <b-form inline>
                  <label class=""> Type:&nbsp;&nbsp; </label>
                  <b-form-select disabled :options="filterByOptions">
                  </b-form-select>
                </b-form>
                <!-- End Type Options -->

                <!-- Per Page Options -->
                <b-form inline>
                  <label class=""> Per Page:&nbsp;&nbsp; </label>
                  <b-form-select
                    disabled
                    v-model="perPage"
                    :options="perPageOptions"
                  >
                  </b-form-select>
                </b-form>
                <!-- End Per Page Options -->
              </b-col>
            </b-row>
            <br />
          </div>
          <div class="card">
            <div v-if="itemsAreLoaded">
              <h5 class="card-header">
                {{ name }}
                <!-- <a href="/create" class="btn btn-success btn-xs float-right">
                Create
              </a> -->
              </h5>
            </div>
            <div v-else-if="apiError">
              <h5 class="card-header text-danger">Error!</h5>
            </div>
            <div v-else>
              <h5 class="card-header">
                <b-skeleton width="15%"></b-skeleton>
              </h5>
            </div>
            <div class="card-body">
              <div v-if="itemsAreLoaded">
                <div v-if="filteredItems.length != 0">
                  <table
                    class="table table-hover table-bordered table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th v-if="type === 'events'" scope="col">Visibility</th>
                        <th scope="col">Created</th>
                        <th scope="col">Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in filteredItems.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )"
                        :key="item.id"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ item.name }}</td>
                        <td v-if="type === 'events'" class="text-center">
                          <font-awesome-icon
                            v-if="item.visibility"
                            class="text-success"
                            :icon="['fas', 'eye']"
                          />
                          <font-awesome-icon
                            v-else
                            class="text-danger"
                            :icon="['fas', 'eye-slash']"
                          />
                        </td>
                        <td>{{ item.createdAt }}</td>
                        <td>{{ item.updatedAt }}</td>
                        <td class="text-center">
                          <router-link
                            :to="'/edit/' + type + '/' + item.id"
                            class="btn btn-primary btn-xs"
                          >
                            Edit
                          </router-link>
                        </td>
                        <td class="text-center">
                          <b-button
                            variant="danger"
                            size="xs"
                            v-b-modal.modalDelete
                            @click="sendToModalDelete(item)"
                          >
                            Delete
                          </b-button>
                        </td>
                      </tr>
                      <b-modal id="modalDelete" v-model="showModal">
                        <template #modal-header>
                          <p class="text-danger">Warning!</p>
                        </template>
                        <p>Are you sure you want to delete this item?</p>
                        <p>
                          <b>{{ item.name }}</b>
                        </p>
                        <template #modal-footer="{ cancel }">
                          <b-button
                            variant="primary"
                            @click="cancel()"
                            :disabled="formProcessing"
                          >
                            No
                          </b-button>
                          <b-button
                            variant="danger"
                            @click="deleteItem(item)"
                            :disabled="formProcessing"
                          >
                            Yes
                          </b-button>
                        </template>
                      </b-modal>
                    </tbody>
                  </table>
                </div>
                <div v-else>No items found!</div>
              </div>
              <div v-else-if="apiError">
                Cannot get data! Please contact administrator!
              </div>
              <div v-else>
                <b-skeleton-table :table-props="{ bordered: true }">
                </b-skeleton-table>
              </div>
            </div>
          </div>
          <div v-if="itemsAreLoaded">
            <Pagination
              :currentPage="currentPage"
              :paginationRows="paginationRows"
              :perPage="perPage"
              align="fill"
              @pageChanged="currentPage = $event"
            />
          </div>
          <div v-else>
            <b-skeleton type="input"></b-skeleton>
          </div>
        </div>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import FilterResults from "@/components/FilterResults.vue";
import Pagination from "@/components/Pagination.vue";

import axios from "axios";
import S3 from "aws-s3";

export default {
  name: "Table",
  props: [
    "name",
    "type",
    "items",
    "itemsAreLoaded",
    "apiError",
    "filterBy",
    "filterByOptions",
  ],
  data() {
    return {
      showModal: false,
      item: "",

      formProcessing: false,

      search: "",

      perPage: 10,
      perPageOptions: ["10", "15", "20"],

      filter: this.filterBy,

      // Pagination
      currentPage: 1,
    };
  },
  components: {
    Search,
    FilterResults,
    Pagination,
  },
  methods: {
    sendToModalDelete(item) {
      this.item = item;
    },
    async deleteItem(item) {
      this.formProcessing = true;

      // if (this.type == "sermon") {
      //   await this.deleteFromS3(item);
      // }

      const token = await this.$auth.getTokenSilently();

      const params = {};

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(`/api/v1/delete/${this.type}/${item.id}`, params, headers)
        .then(res => {
          if (res.data.status === "success") {
            // If all is successful, remove item from array
            this.items.splice(this.items.indexOf(item), 1);
            this.$bvToast.toast(`${item.name} was successfully deleted!`, {
              title: "Item Deleted",
              variant: "success",
              toaster: "b-toaster-top-center",
              solid: true,
            });
          } else {
            this.$bvToast.toast(
              "Could not delete item! Please contact administrator!",
              {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
                solid: true,
              }
            );
          }
          this.formProcessing = false;
        })
        .catch(err => {
          console.error(err);
          this.$bvToast.toast(
            "Something went wrong with the backend! Please contact administrator!",
            {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: true,
            }
          );
          return;
        });
      this.showModal = false;
    },
    async deleteFromS3(item) {
      let S3Client = new S3(this.awsConfig);
      console.log(S3Client);

      // Remove item from AWS S3
      await S3Client.deleteFile(item.url)
        .then(response => {
          console.log(response);
        })
        .catch(err => {
          console.error(err);
          return;
        });
    },
  },
  computed: {
    awsConfig() {
      return {
        bucketName: process.env.VUE_APP_AWS_S3_BUCKET,
        dirName: "sermons/audio",
        region: process.env.VUE_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      };
    },
    filteredItems() {
      if (this.type === "sermon") {
        if (this.filter !== "All") {
          return this.items.filter(item => {
            return (
              item.name.toLowerCase().match(this.search.toLowerCase()) &&
              item.type === this.filter
            );
          });
        } else {
          return this.items.filter(item => {
            return item.name.toLowerCase().match(this.search.toLowerCase());
          });
        }
      } else {
        return this.items.filter(item => {
          return item.name.toLowerCase().match(this.search.toLowerCase());
        });
      }
    },
    paginationRows() {
      return this.filteredItems.length;
    },
  },
};
</script>

<style></style>
