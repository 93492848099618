<template>
  <div id="PreviewTable">
    <b-row>
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="card">
          <div v-if="itemsAreLoaded">
            <h5 class="card-header">
              {{ name }}
              <router-link :to="route" class="small float-right">
                View all
              </router-link>
            </h5>
          </div>
          <div v-else-if="apiError">
            <h5 class="card-header text-danger">Error!</h5>
          </div>
          <div v-else>
            <h5 class="card-header">
              <b-skeleton width="15%"></b-skeleton>
            </h5>
          </div>
          <div class="card-body">
            <div v-if="itemsAreLoaded">
              <table class="table table-hover table-bordered table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in items.slice(0, limit)"
                    :key="item.id"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.name }}</td>
                    <td>{{ item.createdAt }}</td>
                    <td>{{ item.updatedAt }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else-if="apiError">
              Cannot get data! Please contact administrator!
            </div>
            <div v-else>
              <b-skeleton-table :table-props="{ bordered: true }">
              </b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "PreviewTable",
  props: ["name", "route", "items", "limit", "itemsAreLoaded", "apiError"],
};
</script>

<style></style>
