<template>
  <div id="PhotosPage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Photos"
        subtitle="This is the photos page."
        breadcrumb="Content > Photos"
      />
      <div class="ecommerce-widget">
        <ContentTable
          name="Photos"
          base="photos"
          :items="photos"
          :itemsAreLoaded="photosAreLoaded"
          :apiError="apiErrorPhotos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ContentTable from "@/components/ContentTable.vue";

export default {
  name: "PhotosPage",
  components: {
    Header,
    ContentTable,
  },
  data() {
    return {
      photos: [],
      photosAreLoaded: false,
      photoIsDeleting: false,
      apiErrorPhotos: false,
    };
  },
  mounted() {
    this.getAllPhotos();
  },
  methods: {
    getAllPhotos() {
      // Pre-flight checks
      this.photos = [];
      this.photosAreLoaded = false;
      this.photoIsDeleting = false;
      this.apiErrorPhotos = false;
    },
  },
};
</script>

<style></style>
