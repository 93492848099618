<template>
  <div id="VideosPage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Videos"
        subtitle="This is the videos page."
        breadcrumb="Content > Videos"
      />
      <div class="ecommerce-widget">
        <ContentTable
          name="Videos"
          base="videos"
          :items="videos"
          :itemsAreLoaded="videosAreLoaded"
          :apiError="apiErrorVideos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ContentTable from "@/components/ContentTable.vue";

export default {
  name: "VideosPage",
  components: {
    Header,
    ContentTable,
  },
  data() {
    return {
      videos: [],
      videosAreLoaded: false,
      videoIsDeleting: false,
      apiErrorVideos: false,
    };
  },
  mounted() {
    this.getAllVideos();
  },
  methods: {
    getAllVideos() {
      // Pre-flight checks
      this.videos = [];
      this.videosAreLoaded = false;
      this.videoIsDeleting = false;
      this.apiErrorVideos = false;
    },
  },
};
</script>

<style></style>
