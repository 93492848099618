<template>
  <div id="Header">
    <b-row>
      <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="page-header">
          <h1>{{ title }}</h1>
          <p class="page-header-text">{{ subtitle }}</p>
          <div class="page-breadcrumb">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                {{
                  breadcrumb
                }}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["title", "subtitle", "breadcrumb"],
};
</script>

<style></style>
