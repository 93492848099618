<template>
  <div id="EventsPage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Events"
        subtitle="This is the events page."
        breadcrumb="Content > Events"
      />
      <div class="ecommerce-widget">
        <ContentTable
          name="Events"
          type="events"
          :items="events"
          :itemsAreLoaded="eventsAreLoaded"
          :apiError="apiErrorEvents"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ContentTable from "@/components/ContentTable.vue";

import axios from "axios";

export default {
  name: "EventsPage",
  components: {
    Header,
    ContentTable,
  },
  data() {
    return {
      events: [],
      eventsAreLoaded: false,
      eventIsDeleting: false,
      apiErrorEvents: false,
    };
  },
  mounted() {
    this.getAllEvents();
  },
  methods: {
    async getAllEvents() {
      // Pre-flight checks
      this.events = [];
      this.eventsAreLoaded = false;
      this.eventIsDeleting = false;
      this.apiErrorEvents = false;

      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/events/all`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.events = res.data;
          this.eventsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorEvents = true;
        });
    },
  },
};
</script>

<style></style>
