<template>
  <div id="EventForm">
    <b-overlay :show="formProcessing" rounded="sm">
      <div class="card">
        <h5 v-if="formType === 'create'" class="card-header">
          Create an Event
        </h5>
        <h5 v-if="formType === 'edit'" class="card-header">Edit an Event</h5>
        <div class="card-body">
          <!-- Name -->
          <b-form-group>
            <label for="name" class="col-form-label">Name</label>
            <b-form-input
              id="name"
              v-model="name"
              type="text"
              class="form-control was-validated"
              placeholder="Name"
              :disabled="formProcessing"
              aria-invalid="true"
              required
              :validate="false"
            ></b-form-input>
          </b-form-group>
          <!-- Preview -->
          <div class="form-group">
            <label for="preview" class="col-form-label">Preview</label>
            <input
              id="preview"
              v-model="preview"
              type="text"
              class="form-control"
              placeholder="Preview"
              :disabled="formProcessing"
              required
            />
          </div>
          <!-- Message -->
          <div class="form-group">
            <label for="message" class="col-form-label">Message</label>
            <b-form-textarea
              id="message"
              v-model="message"
              class="form-control"
              placeholder="Message"
              rows="3"
              max-rows="6"
              :disabled="formProcessing"
              required
            ></b-form-textarea>
          </div>
          <!-- Visibility -->
          <div class="form-group">
            <b-form-checkbox
              id="visibility"
              v-model="visibility"
              name="visibility"
              :value="true"
              :unchecked-value="null"
            >
              Show event?
            </b-form-checkbox>
          </div>
        </div>
        <div class="card-footer d-flex text-muted">
          <div class="col-sm-6 pl-0 mr-auto">
            <b-button
              type="submit"
              class="btn-space"
              variant="success"
              @click="submit"
              :disabled="formProcessing"
            >
              Submit
            </b-button>
          </div>
          <b-button
            type="reset"
            class="btn-space"
            variant="secondary"
            @click="reset"
            :disabled="formProcessing"
          >
            Clear
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

let slugify = require("slugify");

let slugOptions = {
  replacement: "-",
  remove: /[~!@#$%^&*_=+\|?<>,;.(){}'":]/g,
  lower: true,
  strict: false,
  locale: "en",
  trim: true,
};

export default {
  name: "EventForm",
  data() {
    return {
      formType: "",
      base: "",

      id: "",
      name: "",
      preview: "",
      message: "",
      slug: "",
      visibility: null,

      formErrors: 0,
      formHasErrors: false,
      formProcessing: false,
      apiErrorUpdatingEvent: false,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      // If there is a type in the route params,
      // Edit the event
      if (this.$route.params.type) {
        this.formType = "edit";
        this.base = this.$route.params.type;
        this.id = this.$route.params.id;
        this.getEvent();
      }
      // Else, create a new event
      else {
        this.formType = "create";
      }
    },
    async getEvent() {
      const token = await this.$auth.getTokenSilently();

      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/event/id/${this.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.name = res.data.name;
          this.preview = res.data.preview;
          this.message = res.data.message;
          this.visibility = res.data.visibility;
          this.formProcessing = false;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorUpdatingEvent = true;
        });
    },
    submit() {
      // Form Validation
      this.formValidation();

      if (this.formErrors === 0) {
        if (this.formType === "create") {
          this.createNewEvent();
        } else if (this.formType === "edit") {
          this.editEvent();
        }
      } else {
        this.formHasErrors = true;
        this.formProcessing = false;
      }
    },
    formValidation() {
      // Pre-flight checks
      this.formErrors = 0;
      this.formHasErrors = false;
      this.formProcessing = true;

      if (this.name === "") {
        this.formErrors++;
      }
      if (this.preview === "") {
        this.formErrors++;
      }
      if (this.message === "") {
        this.formErrors++;
      }
    },
    capitalizeName(name) {
      // If name is not empty,
      // Then capitalize the first letter of each word in the name
      return name
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
    },
    getSlug(input) {
      let output = slugify(input, slugOptions);

      return output;
    },
    async createNewEvent() {
      const token = await this.$auth.getTokenSilently();

      // Pre-flight checks
      this.formErrors = 0;
      this.formHasErrors = false;
      this.apiErrorCreatingEvent = false;
      this.formProcessing = true;

      // Timestamps
      let createdAt = new Date();
      let updatedAt = new Date();

      await axios
        .post(`${process.env.VUE_APP_API}/api/v1/create/event`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          name: this.capitalizeName(this.name),
          preview: this.preview,
          message: this.message,
          slug: this.getSlug(this.name),
          adminID: this.$auth.user.nickname,
          visibility: this.visibility,
          createdAt,
          updatedAt,
        })
        .then(res => {
          this.showToast();
          this.reset();
          this.formProcessing = false;
        })
        .catch(err => {
          console.error(err);
          this.apiErrorCreatingEvent = true;
          return;
        });
    },
    async editEvent() {
      // Pre-flight checks
      this.formErrors = 0;
      this.formHasErrors = false;
      this.apiErrorUpdatingEvent = false;
      this.formProcessing = true;

      // Timestamps
      let updatedAt = new Date();

      await axios
        .post(`${process.env.VUE_APP_API}/api/v1/update/event/${this.id}`, {
          name: this.capitalizeName(this.name),
          preview: this.preview,
          message: this.message,
          slug: this.getSlug(this.name),
          adminID: this.$auth.user.nickname,
          visibility: this.visibility,
          updatedAt,
        })
        .then(res => {
          this.showToast();
          this.reset();
          this.formProcessing = false;
        })
        .catch(err => {
          console.error(err);
          this.apiErrorUpdatingEvent = true;
          return;
        });
    },
    showToast() {
      if (this.formType === "create") {
        this.$bvToast.toast(`${this.name} was successfully created!`, {
          title: "Event Created",
          variant: "success",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      } else if (this.formType === "edit") {
        this.$bvToast.toast(`${this.name} was successfully updated!`, {
          title: "Event Updated",
          variant: "success",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      }
    },
    reset() {
      if (this.formType === "create") {
        this.name = "";
        this.preview = "";
        this.message = "";
        this.slug = "";
        this.visibility = false;
      } else if (this.formType === "edit") {
        this.formProcessing = true;
        this.getEvent();
        this.formProcessing = false;
      }
    },
  },
};
</script>

<style></style>
