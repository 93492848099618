<template>
  <div id="SermonForm">
    <b-overlay :show="formProcessing" rounded="sm">
      <div class="card">
        <div v-if="!apiErrorSermonTypes">
          <h5 v-if="formType === 'create'" class="card-header">
            Create a Sermon
          </h5>
          <h5 v-if="formType === 'edit'" class="card-header">Edit a Sermon</h5>
        </div>
        <div v-else>
          <h5 class="card-header text-danger">Error!</h5>
        </div>
        <div class="card-body">
          <div v-if="sermonTypesAreLoaded">
            <div v-if="sermonTypeOptions.length > 0">
              <!-- Name -->
              <b-form-group>
                <label for="name" class="col-form-label">Name</label>
                <b-form-input
                  id="name"
                  v-model="name"
                  type="text"
                  class="form-control was-validated"
                  placeholder="Name"
                  :disabled="formProcessing"
                  aria-invalid="true"
                  required
                  :validate="false"
                ></b-form-input>
              </b-form-group>
              <!-- Speaker -->
              <div class="form-group">
                <label for="speaker" class="col-form-label">Speaker</label>
                <input
                  id="speaker"
                  v-model="speaker"
                  type="text"
                  class="form-control"
                  placeholder="Speaker"
                  :disabled="formProcessing"
                  required
                />
              </div>
              <!-- Date -->
              <div class="form-group">
                <label for="date" class="col-form-label">Date</label>
                <input
                  id="date"
                  v-model="date"
                  type="date"
                  class="form-control"
                  placeholder="Date"
                  :disabled="formProcessing"
                  required
                />
              </div>
              <!-- File -->
              <div class="form-group">
                <label class="col-form-label">File</label>
                <b-form-file
                  v-model="file"
                  accept="audio/*, video/*"
                  :state="Boolean(file)"
                  :disabled="formProcessing"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                >
                </b-form-file>
                <small v-if="uploadedFileName != ''">
                  <span class="text-danger">WARNING</span>:
                  <b>{{ uploadedFileName }}</b> is already uploaded. If you want
                  to change it, please select a new file.
                </small>
              </div>
              <!-- Type -->
              <label>Type</label>
              <b-form-select
                class="text-dark"
                size="sm"
                v-model="sermonType"
                :options="sermonTypeOptions"
                :disabled="formProcessing"
              >
              </b-form-select>
            </div>
            <div v-else>Error!</div>
          </div>
          <div v-else-if="apiErrorSermonTypes">
            Error, api couldn't get types
          </div>
          <div v-else>
            <b-skeleton width="10%"></b-skeleton>
            <b-skeleton type="input"></b-skeleton>
            <br />
            <b-skeleton width="15%"></b-skeleton>
            <b-skeleton type="input"></b-skeleton>
            <br />
            <b-skeleton width="20%"></b-skeleton>
            <b-skeleton type="input"></b-skeleton>
            <br />
            <b-skeleton width="65%"></b-skeleton>
          </div>
        </div>
        <div class="card-footer d-flex text-muted">
          <div class="col-sm-6 pl-0 mr-auto">
            <b-button
              type="submit"
              class="btn-space"
              variant="success"
              @click="submit"
              :disabled="formProcessing"
            >
              Submit
            </b-button>
          </div>
          <b-button
            type="reset"
            class="btn-space"
            variant="secondary"
            @click="reset"
            :disabled="formProcessing"
          >
            Clear
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import S3 from "aws-s3";
let moment = require("moment");

let Airtable = require("airtable");
let base = new Airtable({
  apiKey: process.env.VUE_APP_AIRTABLE_API_KEY,
}).base(process.env.VUE_APP_AIRTABLE_BASE);
let slugify = require("slugify");

let slugOptions = {
  replacement: "-",
  remove: /[~!@#$%^&*_=+\|?<>,;.(){}'":]/g,
  lower: true,
  strict: false,
  locale: "en",
  trim: true,
};

export default {
  name: "SermonForm",
  data() {
    return {
      formType: "",
      base: "",

      typeDefault: "",
      sermonType: "",
      sermonTypeOptions: ["Select a type..."],
      sermonTypesAreLoaded: false,
      apiErrorSermonTypes: false,

      id: "",
      name: "",
      speaker: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      file: null,
      fileName: "",
      uploadedFileName: "",

      formErrors: 0,
      formHasErrors: false,
      formProcessing: false,
      apiErrorUpdatingSermon: false,
    };
  },
  mounted() {
    this.initialize();
    this.getSermonTypes();
  },
  methods: {
    initialize() {
      // If there is a type in the route params,
      // Edit the sermon
      if (this.$route.params.type) {
        this.formType = "edit";
        this.base = this.$route.params.type;
        this.id = this.$route.params.id;
        this.typeDefault = "Select a type...";
        this.getSermon();
      }
      // Else, create a new sermon
      else {
        this.formType = "create";
        this.typeDefault = "Select a type...";
        this.sermonType = "Select a type...";
      }
    },
    async getSermon() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/sermon/${this.id}`)
        .then(res => {
          this.name = res.data["name"];
          this.speaker = res.data["speaker"];
          this.uploadedFileName = res.data["url"];
          this.date = moment(res.data["date"]).format("yyyy-MM-DD");
          this.sermonType = res.data["type"];
          this.formProcessing = false;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermonTypes = true;
        });
    },
    async getSermonTypes() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/types/sermons`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.sermonTypeOptions = res.data;
          this.sermonTypesAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermonTypes = true;
        });
    },
    submit() {
      // Form Validation
      this.formValidation();

      if (this.formErrors === 0) {
        if (this.formType === "create") {
          this.createNewSermon();
        } else if (this.formType === "edit") {
          this.editSermon();
        }
      } else {
        this.formHasErrors = true;
        this.formProcessing = false;
      }
    },
    formValidation() {
      // Pre-flight checks
      this.formErrors = 0;
      this.formHasErrors = false;
      this.formProcessing = true;

      if (this.name === "") {
        this.formErrors++;
      }
      if (this.speaker === "") {
        this.formErrors++;
      }
      if (this.formType === "create") {
        if (this.file === null) {
          this.formErrors++;
        }
      } else if (this.formType === "edit") {
        if (this.file === null) {
          this.fileName = this.uploadedFileName;
        } else this.fileName = this.file.name;
      }
      if (this.sermonType === this.typeDefault) {
        this.formErrors++;
      }
    },
    getFileNameAndSlug(input) {
      // Create array of string by '.'
      let splitInput = input.split(".");
      // Remove last element (e.g. '.wav', '.mp3', etc.)
      splitInput.pop();
      let smush = "";
      // Smush everything back into a string
      for (let i = 0; i < splitInput.length; i++) {
        smush += splitInput[i];
      }
      let output = slugify(smush, slugOptions);

      return output;
    },
    async createNewSermon() {
      // Pre-flight checks
      this.formErrors = 0;
      this.formHasErrors = false;
      this.apiErrorCreatingSermon = false;
      this.formProcessing = true;

      let uploadedFile = "";
      // var isVideo = false;

      // if (this.file.type.startsWith("video")) {
      //   // Declare sermon as a video
      //   isVideo = true;
      // }

      try {
        uploadedFile = await this.uploadFile();
      } catch (err) {
        console.log(err);
      }

      if (uploadedFile != "") {
        const token = await this.$auth.getTokenSilently();

        const params = {
          name: this.name,
          speaker: this.speaker,
          url: uploadedFile,
          type: this.sermonType,
          // isVideo: this.isVideo,
          date: this.date,
        };

        const headers = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/create/sermon`,
            params,
            headers
          )
          .then(res => {
            this.showToast();
            this.formProcessing = false;
            this.reset();
          })
          .catch(err => {
            console.error(err);
            this.apiErrorCreatingSermon = true;
            return;
          });
      } else this.formProcessing = false;
    },
    async editSermon() {
      // Pre-flight checks
      this.formErrors = 0;
      this.formHasErrors = false;
      this.apiErrorUpdatingSermon = false;
      this.formProcessing = true;

      let uploadedFile = "";

      if (this.file !== null) {
        try {
          uploadedFile = await this.uploadFile();
        } catch (err) {
          console.log(err);
        }
      } else uploadedFile = this.uploadedFileName;

      const token = await this.$auth.getTokenSilently();

      const params = {
        name: this.name,
        speaker: this.speaker,
        url: uploadedFile,
        date: this.date,
        type: this.sermonType,
      };

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/update/sermon/${this.id}`,
          params,
          headers
        )
        .then(res => {
          if (uploadedFile === this.uploadedFileName) {
            this.showToast();
          }
          this.formProcessing = false;
          this.reset();
        })
        .catch(err => {
          console.error(err);
          this.apiErrorUpdatingSermon = true;
          return;
        });
    },
    async uploadFile() {
      let returnData = "";
      let S3Client = new S3(this.awsConfig);

      await S3Client.uploadFile(
        this.file,
        this.getFileNameAndSlug(this.file.name)
      )
        .then(data => {
          // Grab actual file name that is uploaded to AWS
          // Reason for this is because file extensions will be different
          // But we want them to be in compliance across AWS and Airtable
          returnData = data.key.split("sermons/audio/")[1];
          // Reset form
          if (this.formType === "create") {
            // this.showToast();
            return;
          } else if (this.formType === "edit") {
            this.getSermon();
            this.file = null;
            // this.showToast();
          }
        })
        .catch(err => {
          console.error(err);
          this.$bvToast.toast(
            "Failed to upload sermon! Please contact administrator!",
            {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: true,
            }
          );
          return;
        });
      return returnData;
    },
    showToast() {
      if (this.formType === "create") {
        this.$bvToast.toast(`${this.name} was successfully created!`, {
          title: "Sermon Created",
          variant: "success",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      } else if (this.formType === "edit") {
        this.$bvToast.toast(`${this.name} was successfully updated!`, {
          title: "Sermon Updated",
          variant: "success",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      }
    },
    reset() {
      if (this.formType === "create") {
        this.name = "";
        this.speaker = "";
        this.file = null;
        this.sermonType = this.typeDefault;
      } else if (this.formType === "edit") {
        this.formProcessing = true;
        this.getSermon();
        this.formProcessing = false;
      }
    },
  },
  computed: {
    awsConfig() {
      return {
        bucketName: process.env.VUE_APP_AWS_S3_BUCKET,
        dirName: "sermons/audio",
        region: process.env.VUE_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
        s3Url: process.env.VUE_APP_AWS_S3_URI,
      };
    },
  },
};
</script>

<style></style>
