<template>
  <div id="FilterResults">
    <b-row class="mb-4">
      <b-col class="d-flex justify-content-between">
        <div v-if="type == 'sermons'">
          <!-- Type Options -->
          <b-form inline>
            <label> Type:&nbsp;&nbsp; </label>
            <b-form-select
              :value="filterBy"
              :options="filterByOptions"
              @input="changeFilterBy"
            >
            </b-form-select>
          </b-form>
          <!-- End Type Options -->
        </div>

        <!-- Per Page Options -->
        <b-form inline>
          <label> Per Page:&nbsp;&nbsp; </label>
          <b-form-select
            :value="perPage"
            :options="perPageOptions"
            @input="changePerPage"
          >
          </b-form-select>
        </b-form>
        <!-- End Per Page Options -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FilterResults",
  props: ["type", "filterBy", "filterByOptions", "perPage", "perPageOptions"],
  methods: {
    changeFilterBy(event) {
      this.$emit("filterByChanged", event);
    },
    changePerPage(event) {
      this.$emit("perPageChanged", event);
    },
  },
};
</script>

<style></style>
