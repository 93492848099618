<template>
  <div id="SermonsPage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Sermons"
        subtitle="This is the sermons page."
        breadcrumb="Content > Sermons"
      />
      <div class="ecommerce-widget">
        <ContentTable
          name="Sermons"
          type="sermon"
          :items="sermons"
          :itemsAreLoaded="sermonsAreLoaded"
          :apiError="apiErrorSermons"
          :filterBy="sermonType"
          :filterByOptions="sermonTypeOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ContentTable from "@/components/ContentTable.vue";

import axios from "axios";
let moment = require("moment");

export default {
  name: "SermonsPage",
  components: {
    Header,
    ContentTable,
  },
  data() {
    return {
      apiErrorSermons: false,
      apiErrorSermonTypes: false,

      sermons: [],
      sermonsAreLoaded: false,

      sermonTypesAreLoaded: false,
      sermonType: "All",
      sermonTypeOptions: ["All"],
    };
  },
  mounted() {
    this.getAllSermons();
    this.getSermonTypes();
  },
  methods: {
    async getAllSermons() {
      // Pre-flight checks
      this.sermons = [];
      this.sermonsAreLoaded = false;
      this.sermonIsDeleting = false;
      this.apiErrorSermons = false;

      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/sermons`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          res.data.map(sermon => {
            this.sermons.push({
              id: sermon.id,
              name: sermon.name,
              url: sermon.url,
              type: sermon.type,
              date: sermon.date,
              createdAt: moment(sermon.createdAt).format("MMMM Do, YYYY"),
              updatedAt: moment(sermon.updatedAt).format("MMMM Do, YYYY"),
            });
          });
          this.sermonsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermons = true;
        });
    },
    async getSermonTypes() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/types/sermons`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.sermonTypes = res.data;
          this.sermonTypesAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermonTypes = true;
        });
    },
  },
};
</script>

<style></style>
