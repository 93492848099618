<template>
  <div id="RegistrationsPage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Registrations"
        subtitle="This is the registrations page."
        breadcrumb="Content > Registrations"
      />
      <Registrations />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Registrations from "@/components/Registrations.vue";

export default {
  name: "RegistrationsPage",
  components: {
    Header,
    Registrations,
  },
};
</script>

<style></style>
