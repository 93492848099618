<template>
  <div id="Sidebar">
    <div class="nav-left-sidebar sidebar-dark">
      <div class="menu-list">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link class="d-xl-none d-lg-none" to="/">
            Dashboard
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <!-- <span class="navbar-toggler-icon"></span> -->
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav flex-column">
              <!-- Menu -->
              <li class="nav-divider">Menu</li>
              <li class="nav-item">
                <router-link class="nav-link" to="/">
                  <font-awesome-icon :icon="['fas', 'home']" />&nbsp; Dashboard
                  <span class="badge badge-success">6</span>
                </router-link>
              </li>
              <!-- Content -->
              <li class="nav-item">
                <router-link to="" class="nav-link" v-b-toggle.collapse-content>
                  <font-awesome-icon :icon="['fas', 'layer-group']" />&nbsp;
                  Content
                </router-link>
              </li>
              <b-collapse id="collapse-content" class="mt-2">
                <ul class="nav flex-column" background="primary">
                  <li class="nav-item pl-3">
                    <router-link class="nav-link" to="/events">
                      <font-awesome-icon :icon="['fas', 'calendar']" />&nbsp;
                      Events
                    </router-link>
                  </li>
                  <li class="nav-item pl-3">
                    <router-link class="nav-link" to="/sermons">
                      <font-awesome-icon :icon="['fas', 'cross']" />&nbsp;
                      Sermons
                    </router-link>
                  </li>
                  <li class="nav-item pl-3">
                    <router-link class="nav-link" to="/photos">
                      <font-awesome-icon :icon="['fas', 'images']" />&nbsp;
                      Photos
                    </router-link>
                  </li>
                  <li class="nav-item pl-3">
                    <router-link class="nav-link" to="/videos">
                      <font-awesome-icon :icon="['fas', 'video']" />&nbsp;
                      Videos
                    </router-link>
                  </li>
                  <li class="nav-item pl-3">
                    <router-link class="nav-link" to="/registrations">
                      <font-awesome-icon
                        :icon="['fas', 'address-book']"
                      />&nbsp; Registrations
                    </router-link>
                  </li>
                </ul>
              </b-collapse>
              <!-- End Content -->
              <!-- End Menu -->

              <!-- Types -->
              <li class="nav-item">
                <router-link to="" class="nav-link" v-b-toggle.collapse-types>
                  <div>
                    <font-awesome-icon :icon="['fas', 'layer-group']" />&nbsp;
                    Types
                  </div>
                </router-link>
              </li>
              <b-collapse id="collapse-types" class="mt-2">
                <ul class="nav flex-column">
                  <li class="nav-item pl-3">
                    <router-link class="nav-link" to="/sermons">
                      <font-awesome-icon :icon="['fas', 'cross']" />&nbsp;
                      Sermons
                    </router-link>
                  </li>
                </ul>
              </b-collapse>
              <!-- End Types -->

              <!-- Administration Subsection -->
              <li class="nav-divider">Administration</li>
              <li class="nav-item">
                <a class="nav-link" :href="analyticsUri + '/'">
                  <font-awesome-icon :icon="['fas', 'chart-pie']" />
                  &nbsp; Analytics
                </a>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/notifications">
                  <font-awesome-icon :icon="['fas', 'bell']" />
                  &nbsp; Notifications
                </router-link>
              </li>
              <!-- End Administration Subsection -->

              <li class="nav-divider">Version {{ version }}</li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      analyticsUri: process.env.VUE_APP_ANALYTICS_URI,
    };
  },
};
</script>

<style></style>
