import Vue from "vue";
import VueRouter from "vue-router";

// Authentication
// import LoginPage from '../views/LoginPage.vue'
// import ForgotPasswordPage from '../views/ForgotPasswordPage.vue'
// import RegisterPage from '../views/RegisterPage.vue'

// Main
import DashboardPage from "../views/DashboardPage.vue";

// Content
import EventsPage from "../views/EventsPage.vue";
import SermonsPage from "../views/SermonsPage.vue";
import PhotosPage from "../views/PhotosPage.vue";
import VideosPage from "../views/VideosPage.vue";
import RegistrationsPage from "../views/RegistrationsPage.vue";

// Administration
import AnalyticsPage from "../views/AnalyticsPage.vue";
import NotificationsPage from "../views/NotificationsPage.vue";

// Misc
import Error404Page from "../views/Error404Page.vue";

//CRUD
import CreatePage from "../views/CreatePage.vue";
import EditPage from "../views/EditPage.vue";

// Auth0
import { authGuard } from "@/auth/authGuard";

Vue.use(VueRouter);

const routes = [
  // Authentication
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: LoginPage,
  // },
  // {
  //   path: '/forgot',
  //   name: 'ForgotPassword',
  //   component: ForgotPasswordPage,
  // },
  // {
  //   path: '/sign-up',
  //   name: 'Register',
  //   component: RegisterPage,
  // },
  // Main
  {
    path: "/",
    name: "Dashboard",
    component: DashboardPage,
    beforeEnter: authGuard,
  },
  // Content
  {
    path: "/events",
    name: "Events",
    component: EventsPage,
    beforeEnter: authGuard,
  },
  {
    path: "/sermons",
    name: "Sermons",
    component: SermonsPage,
    beforeEnter: authGuard,
  },
  {
    path: "/photos",
    name: "Photos",
    component: PhotosPage,
    beforeEnter: authGuard,
  },
  {
    path: "/videos",
    name: "Videos",
    component: VideosPage,
    beforeEnter: authGuard,
  },
  {
    path: "/registrations",
    name: "Registrations",
    component: RegistrationsPage,
    beforeEnter: authGuard,
  },
  // Administration
  {
    path: "/analytics",
    name: "Analytics",
    component: AnalyticsPage,
    beforeEnter: authGuard,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsPage,
    beforeEnter: authGuard,
  },
  {
    path: "*",
    name: "Error 404",
    component: Error404Page,
    beforeEnter: authGuard,
  },
  // CRUD
  {
    path: "/create",
    name: "Create",
    component: CreatePage,
    beforeEnter: authGuard,
  },
  {
    path: "/edit/:type/:id",
    name: "Edit",
    component: EditPage,
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // console.log(router.app.$auth.isAuthenticated)
//   if(!router.app.$auth.isAuthenticated) {
//     console.log('this is true')
//     next('/login')
//   }
//   else {
//     next()
//   }
// })

// router.beforeEach((to, from, next) => {
//   authenticationGuard
//   next()
// })

export default router;
