<template>
  <div id="DashboardPage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Dashboard"
        subtitle="This is the home page."
        breadcrumb="Dashboard"
      />
      <div class="ecommerce-widget">
        <!-- Greeting -->
        <div class="page-section" id="overview">
          <div class="row">
            <div class="col-12 pb-5">
              <h2>Welcome, {{ $auth.user.name }}!</h2>
              <p class="lead">It is currently {{ time }} on {{ date }}</p>
            </div>
          </div>
        </div>
        <!-- End Greeting -->

        <!-- Aggregates -->
        <b-row>
          <!-- Total Events -->
          <!-- <Aggregate
            name="Events"
            :items="events"
            :itemsAreLoaded="eventsAreLoaded"
            :apiError="apiErrorEvents"
          /> -->
          <!-- End Total Events -->

          <!-- Total Sermons -->
          <Aggregate
            name="Sermons"
            :items="sermons"
            :itemsAreLoaded="sermonsAreLoaded"
            :apiError="apiErrorSermons"
          />
          <!-- End Total Sermons -->

          <!-- Total Photos -->
          <!-- <Aggregate
            name="Photos"
            :items="photos"
            :itemsAreLoaded="photosAreLoaded"
            :apiError="apiErrorPhotos"
          /> -->
          <!-- End Total Photos -->

          <!-- Total Videos -->
          <!-- <Aggregate
            name="Videos"
            :items="videos"
            :itemsAreLoaded="videosAreLoaded"
            :apiError="apiErrorVideos"
          /> -->
          <!-- End Total Videos -->
        </b-row>
        <!-- End Aggregates -->

        <!-- <PreviewTable
          name="Events"
          route="/events"
          :items="events"
          limit="5"
          :itemsAreLoaded="eventsAreLoaded"
          :apiError="apiErrorEvents"
        /> -->
        <PreviewTable
          name="Sermons"
          route="/sermons"
          :items="sermons"
          limit="5"
          :itemsAreLoaded="sermonsAreLoaded"
          :apiError="apiErrorSermons"
        />
        <!-- <PreviewTable
          name="Photos"
          route="/photos"
          :items="photos"
          limit="5"
          :itemsAreLoaded="photosAreLoaded"
          :apiError="apiErrorPhotos"
        />
        <PreviewTable
          name="Videos"
          route="/videos"
          :items="videos"
          limit="5"
          :itemsAreLoaded="videosAreLoaded"
          :apiError="apiErrorVideos"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Aggregate from "@/components/Aggregate.vue";
import PreviewTable from "@/components/PreviewTable.vue";

let moment = require("moment");
import axios from "axios";

export default {
  name: "DashboardPage",
  components: {
    Header,
    Aggregate,
    PreviewTable,
  },
  props: ["subtitle", "breadcrumb"],
  data() {
    return {
      time: moment().format("h:mm A"),
      date: moment().format("MMMM Do, Y."),

      events: [],
      eventsAreLoaded: false,
      apiErrorEvents: false,

      sermons: [],
      sermonsAreLoaded: false,
      apiErrorSermons: false,

      photos: [],
      photosAreLoaded: false,
      apiErrorPhotos: false,

      videos: [],
      videosAreLoaded: false,
      apiErrorVideos: false,
    };
  },
  mounted() {
    // this.getAllEvents();
    this.getAllSermons();
    // this.getAllPhotos();
    // this.getAllVideos();
  },
  methods: {
    async getAllEvents() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/events/all`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.events = res.data;
          this.eventsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorEvents = true;
        });
    },
    async getAllSermons() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/sermons`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.sermons = res.data;
          this.sermonsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermons = true;
        });
    },
    async getAllPhotos() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/photos`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.photos = res.data;
          this.photosAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorPhotos = true;
        });
    },
    async getAllVideos() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/v1/videos`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.videos = res.data;
          this.videosAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorVideos = true;
        });
    },
  },
};
</script>
