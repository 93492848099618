<template>
  <div id="Aggregate" class="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
    <div class="card border-3 border-top border-top-primary">
      <div class="card-body">
        <h5 class="text-muted">{{ name }}</h5>
        <div class="metric-value d-inline-block">
          <div v-if="itemsAreLoaded">
            <h1 class="mb-1">{{ items.length }}</h1>
          </div>
          <div v-else-if="apiError">
            <h1 class="mb-1 text-danger">
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
            </h1>
          </div>
          <div v-else class="text-center">
            <h1 class="mb-1">
              <h1 class="mb-1">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </h1>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Aggregate",
  props: ["name", "items", "itemsAreLoaded", "apiError"],
};
</script>

<style></style>
