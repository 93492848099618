<template>
  <div id="Registrations">
    <b-overlay :show="formProcessing" rounded="sm">
      <b-row>
        <!-- Total Attendees -->
        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-inline-block">
                <h5 class="text-muted">Total Attendees</h5>
                <h2 class="mb-0">{{ totalAttending }}</h2>
              </div>
              <div
                class="float-right icon-circle-medium icon-box-lg bg-info-light mt-1"
              >
                <h3><font-awesome-icon :icon="['fas', 'users']" /></h3>
              </div>
            </div>
          </div>
        </div>
        <!-- End Total Attendees -->

        <!-- Total Sharing -->
        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-inline-block">
                <h5 class="text-muted">Total Sharing</h5>
                <h2 class="mb-0">{{ totalSharing }}</h2>
              </div>
              <div
                class="float-right icon-circle-medium icon-box-lg bg-primary-light mt-1"
              >
                <h3><font-awesome-icon :icon="['fas', 'microphone-alt']" /></h3>
              </div>
            </div>
          </div>
        </div>
        <!-- End Total Sharing -->

        <!-- Total Singing -->
        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-inline-block">
                <h5 class="text-muted">Total Singing</h5>
                <h2 class="mb-0">{{ totalSinging }}</h2>
              </div>
              <div
                class="float-right icon-circle-medium icon-box-lg bg-danger-light mt-1"
              >
                <h3><font-awesome-icon :icon="['fas', 'guitar']" /></h3>
              </div>
            </div>
          </div>
        </div>
        <!-- End Total Singing -->
      </b-row>

      <!-- Registrations -->
      <b-row>
        <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="registrationsAreLoaded">
            <!-- <Search :search="search" @searchChanged="search = $event" /> -->
            <b-row class="mb-4">
              <b-col class="d-flex justify-content-between">
                <!-- Per Page Options -->
                <b-form inline>
                  <label> Per Page:&nbsp;&nbsp; </label>
                  <b-form-select
                    :value="perPage"
                    :options="perPageOptions"
                    @change="perPage = $event"
                  >
                  </b-form-select>
                </b-form>
                <!-- End Per Page Options -->
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-input-group class="mb-4">
              <b-form-input disabled></b-form-input>
            </b-input-group>
            <b-row class="mb-4">
              <b-col class="d-flex justify-content-end">
                <!-- Per Page Options -->
                <b-form inline>
                  <label class=""> Per Page:&nbsp;&nbsp; </label>
                  <b-form-select
                    disabled
                    v-model="perPage"
                    :options="perPageOptions"
                  >
                  </b-form-select>
                </b-form>
                <!-- End Per Page Options -->
              </b-col>
            </b-row>
            <br />
          </div>
          <div class="card">
            <div v-if="registrationsAreLoaded">
              <h5 class="card-header">
                {{ name }}
                <!-- <a href="/create" class="btn btn-success btn-xs float-right">
                Create
              </a> -->
              </h5>
            </div>
            <div v-else-if="apiErrorRegistrations">
              <h5 class="card-header text-danger">Error!</h5>
            </div>
            <div v-else>
              <h5 class="card-header">
                <b-skeleton width="15%"></b-skeleton>
              </h5>
            </div>
            <div class="card-body">
              <div v-if="registrationsAreLoaded">
                <div v-if="registrations.length != 0">
                  <table
                    class="table table-hover table-bordered table-bordered"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Attending</th>
                        <th scope="col">Sharing</th>
                        <th scope="col">Singing</th>
                        <th scope="col">Guests</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in registrations.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )"
                        :key="item.id"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ item.firstName }}</td>
                        <td>{{ item.lastName }}</td>
                        <td>{{ item.email }}</td>
                        <td class="text-center">
                          <font-awesome-icon
                            v-if="item.attending"
                            class="text-success"
                            :icon="['fas', 'calendar-check']"
                          />
                        </td>
                        <td class="text-center">
                          <font-awesome-icon
                            v-if="item.sharing"
                            class="text-success"
                            :icon="['fas', 'microphone-alt']"
                          />
                        </td>
                        <td class="text-center">
                          <font-awesome-icon
                            v-if="item.singing"
                            class="text-success"
                            :icon="['fas', 'guitar']"
                          />
                        </td>
                        <td v-if="item.guests > 0">{{ item.guests }}</td>
                        <td v-else>0</td>
                        <!-- <td class="text-center">
                          <router-link
                            :to="'/edit/' + type + '/' + item.id"
                            class="btn btn-primary btn-xs"
                          >
                            Edit
                          </router-link>
                        </td> -->
                        <td class="text-center">
                          <b-button
                            variant="danger"
                            size="xs"
                            v-b-modal.modalDelete
                            @click="sendToModalDelete(item)"
                          >
                            Delete
                          </b-button>
                        </td>
                      </tr>
                      <b-modal id="modalDelete" v-model="showModal">
                        <template #modal-header>
                          <p class="text-danger">Warning!</p>
                        </template>
                        <p>Are you sure you want to delete this item?</p>
                        <p>
                          <b>{{ item.name }}</b>
                        </p>
                        <template #modal-footer="{ cancel }">
                          <b-button
                            variant="primary"
                            @click="cancel()"
                            :disabled="formProcessing"
                          >
                            No
                          </b-button>
                          <b-button
                            variant="danger"
                            @click="deleteItem(item)"
                            :disabled="formProcessing"
                          >
                            Yes
                          </b-button>
                        </template>
                      </b-modal>
                    </tbody>
                  </table>
                </div>
                <div v-else>No items found!</div>
              </div>
              <div v-else-if="apiErrorRegistrations">
                Cannot get data! Please contact administrator!
              </div>
              <div v-else>
                <b-skeleton-table :table-props="{ bordered: true }">
                </b-skeleton-table>
              </div>
            </div>
          </div>
          <div v-if="registrationsAreLoaded">
            <Pagination
              :currentPage="currentPage"
              :paginationRows="paginationRows"
              :perPage="perPage"
              align="fill"
              @pageChanged="currentPage = $event"
            />
          </div>
          <div v-else>
            <b-skeleton type="input"></b-skeleton>
          </div>
        </div>
      </b-row>
      <!-- End registrations -->
    </b-overlay>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import FilterResults from "@/components/FilterResults.vue";
import Pagination from "@/components/Pagination.vue";

import axios from "axios";

export default {
  name: "Registrations",
  components: {
    Search,
    FilterResults,
    Pagination,
  },
  data() {
    return {
      name: "Registrations",

      registrations: [],
      registrationsAreLoaded: false,
      registrationIsDeleting: false,
      apiErrorRegistrations: false,

      totalAttending: 0,
      totalSharing: 0,
      totalSinging: 0,

      showModal: false,
      item: "",

      formProcessing: false,

      search: "",

      perPage: 10,
      perPageOptions: ["10", "15", "20"],

      filter: this.filterBy,

      // Pagination
      currentPage: 1,
    };
  },
  mounted() {
    this.getRegistrations();
  },
  methods: {
    async getRegistrations() {
      // Pre-flight checks
      this.registrations = [];
      this.registrationsAreLoaded = false;
      this.registrationIsDeleting = false;
      this.apiErrorRegistrations = false;

      await axios
        .get(`/api/v1/registrations`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.registrations = res.data;

          // Populate aggregates
          res.data.map(data => {
            if (data.attending) {
              this.totalAttending++;
            }
            if (data.guests) {
              this.totalAttending += data.guests;
            }
            if (data.sharing) {
              this.totalSharing++;
            }
            if (data.singing) {
              this.totalSinging++;
            }
          });

          this.registrationsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorRegistrations = true;
        });
    },
    // sendToModalDelete(item) {
    //   this.item = item;
    // },
    // async deleteItem(item) {
    //   this.formProcessing = true;
    //   // if (this.type == "sermons") {
    //   //   await this.deleteFromS3(item);
    //   // }
    //   const token = await this.$auth.getTokenSilently();
    //   await axios
    //     .post(`/api/v1/delete/${this.type}/${item.id}`, {
    //       // params: {
    //       //   url: item.url,
    //       // },
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //     .then(res => {
    //       // If all is successful, remove item from array
    //       this.items.splice(this.items.indexOf(item), 1);
    //       this.$bvToast.toast(`${item.name} was successfully deleted!`, {
    //         title: "Item Deleted",
    //         variant: "success",
    //         toaster: "b-toaster-top-center",
    //         solid: true,
    //       });
    //       this.formProcessing = false;
    //     })
    //     .catch(err => {
    //       console.error(err);
    //       this.$bvToast.toast(
    //         "Could not delete item! Please contact administrator!",
    //         {
    //           title: "Error!",
    //           variant: "danger",
    //           toaster: "b-toaster-top-center",
    //           solid: true,
    //         }
    //       );
    //       return;
    //     });
    //   this.showModal = false;
    // },
  },
  computed: {
    // filteredItems() {
    //   return this.registrations.filter(registration => {
    //     return registration.name.toLowerCase().match(this.search.toLowerCase());
    //   });
    // },
    paginationRows() {
      return this.registrations.length;
    },
  },
};
</script>

<style></style>
