<template>
  <div id="CreatePage">
    <div class="dashboard-main-wrapper">
      <Header
        title="Create"
        subtitle="This is the create page."
        breadcrumb=""
      />
      <div class="ecommerce-widget">
        <b-row>
          <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="pb-3">
              <h3>Create a New:</h3>
              <b-form-select
                v-model="createSelect"
                :value="createSelect"
                :options="createSelectOptions"
              >
              </b-form-select>
            </div>
            <div v-if="createSelect.toLowerCase() === 'event'">
              <EventForm />
            </div>
            <div v-if="createSelect.toLowerCase() === 'sermon'">
              <SermonForm />
            </div>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import EventForm from "@/components/EventForm.vue";
import SermonForm from "@/components/SermonForm.vue";

export default {
  name: "CreatePage",
  data() {
    return {
      createSelect: "Sermon",
      createSelectOptions: ["Event", "Sermon", "Photo", "Video"],
    };
  },
  components: {
    Header,
    EventForm,
    SermonForm,
  },
};
</script>

<style></style>
