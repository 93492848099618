<template>
  <div id="Analytics">
    <div class="dashboard-main-wrapper">
      <Header
        title="Analytics"
        subtitle="This is the analytics page."
        breadcrumb="Analytics"
      />
      <div class="ecommerce-widget">Here are your analytics:</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "Analytics",
  components: {
    Header,
  },
};
</script>
