<template>
  <div id="Pagination">
    <b-pagination
      :value="currentPage"
      :total-rows="paginationRows"
      :per-page="perPage"
      :align="align"
      @input="changePage"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["currentPage", "paginationRows", "perPage", "align", "ariaControls"],
  methods: {
    changePage(event) {
      this.$emit("pageChanged", event);
    },
  },
};
</script>

<style></style>
