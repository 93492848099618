import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fab, fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// Custom CSS
import "../public/css/app.css";

// Import Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Auth0
// Import the plugin here
import { Auth0Plugin } from "./auth";

let domain = process.env.VUE_APP_AUTH0_DOMAIN;
let clientId = process.env.VUE_APP_AUTH0_CLIENTID;
let audience = process.env.VUE_APP_AUTH0_AUDIENCE;
let issuer = process.env.VUE_APP_AUTH0_ISSUER;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  // Auth0 'audience' is used in getTokenSilently()
  audience,
  issuer,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.config.productionTip = false;

// Axios
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
