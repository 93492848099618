<template>
  <div id="Notifications">
    <div class="dashboard-main-wrapper">
      <Header
        title="Notifications"
        subtitle="This is the notifications page."
        breadcrumb="Notifications"
      />
      <div class="ecommerce-widget">Your notifications:</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "Notifications",
  components: {
    Header,
  },
};
</script>
