<template>
  <div id="app">
    <b-overlay :show="$auth.loading" opacity="1.0">
      <Navbar />
      <Sidebar />
      <Wrapper />
    </b-overlay>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Wrapper from "@/components/Wrapper.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Sidebar,
    Wrapper,
  },
};
</script>

<style></style>
